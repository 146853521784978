<template>
    <div>
        <v-card class="mt-2"  dark max-width="400" elevation="0">
        <v-list color="#434242">
            <v-list-item>
                <v-list-item-avatar>
                    <v-icon v-if="customerItem.customerBasicInfo.customerGender == 'Female'"> $womanIcon </v-icon>
                    <v-icon  v-if="customerItem.customerBasicInfo.customerGender == 'Male'"> $manIcon </v-icon>
                </v-list-item-avatar>
                <v-list-item-content @click="toViewCustomer(customerItem._id)">
                    <v-list-item-title class="customerTitle">
                        {{customerItem.customerBasicInfo.customerName}}
                    </v-list-item-title>
                    <span class="customerMobile">{{customerItem.customerBasicInfo.customerMobile}}</span>
                </v-list-item-content>

                <v-list-item-content class="text-right">
                    <v-list-item-title class="houseNo">{{customerItem.customerCommunityInfo.customerHouseNo}}</v-list-item-title>
                    <span class="customerCommunity">{{customerItem.customerCommunityInfo.customerCommunityId.communityName}}</span>
                    <div class="text-right mt-2">
                    <v-icon @click="toEditCustomer(customerItem._id)" class="houseText flexUnset p-2 bg-green mr-2" color="#ffffff" dark>mdi-square-edit-outline</v-icon>
                    <v-icon @click="toRemoveCustomer(customerItem._id)" class="houseText flexUnset p-2 bg-danger" color="#ffffff" dark>mdi-trash-can-outline</v-icon>
                    </div>
                </v-list-item-content>

            </v-list-item>
        </v-list>
    </v-card>
    </div>
</template>
<script>
import router from '@/router';

  export default {
    name: 'ListCustomerCard',
    components: {
    },
    props: {
        customerItem: Object,
        toRemoveCustomer:Function
    },
    data () {
        return {
            loader: false
        }
    },
    methods: {
        toViewCustomer (customerId) {
            router.push({name: "viewCustomer", params: { customerId: customerId }})
            // this.$router.push({ name: "view-branches", params: { previousRoute: 'branches' } })
        },
		toEditCustomer ( id ) {
            router.push('editCustomer/'+id)
        }
    }
  }
</script>
<style scoped>
.customerTitle {
font-family: Lato;
font-style: normal;
font-weight: 900;
font-size: 18px;
color: #FFFFFF;
}
.customerMobile {
font-family: Lato;
font-style: regular;
font-weight: 700;
font-size: 14px;
line-height: 15px;
color: #C7C6C6;
}
.customerCommunity {
font-family: Lato;
font-style: regular;
font-weight: 700;
font-size: 12px;
line-height: 15px;
color: #C7C6C6;
}

.houseNo {
font-family: Montserrat;
font-style: normal;
font-weight: 900;
font-size: 16px;
color: #FFFFFF;
}

.houseText {
font-family: Montserrat;
font-style: normal;
font-weight: 700;
font-size: 14px;
color: #FFFFFF;
}

.flexUnset{
flex: unset;
}
.p-2 {
    padding: 5px;
}
.bg-danger{
background: #d31313;
}
.bg-green {
    background: #4b9c6d;
}
</style>
