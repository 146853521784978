import Api from '@/services/Api'

export default {
  viewPartnerCommunities(partnerId) {
    return Api().get(`/api/partner/viewPartnerCommunities/${partnerId}`)
  },
  createCustomer(customerInfo) {
    return Api().post('/api/partner/createCustomer', customerInfo)
  },
  updateCustomer(customerInfo) {
    return Api().post('/api/partner/updateCustomer/', customerInfo)
  },
  viewMyCustomers() {
    return Api().get('/api/partner/viewMyCustomers')
  },
  viewCustomerActiveOrder(customerId, orderType) {
    return Api().get(`/api/partner/viewCustomerActiveOrder/${customerId}/${orderType}`)
  },
  viewCustomer(customerId) {
    return Api().get(`api/partner/viewCustomer/${customerId}`)
  },
  removeCustomer(customerId) {
    return Api().delete(`api/partner/removeCustomer/${customerId}`)
  }
}