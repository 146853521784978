<template>
    <div>

    <v-row class="mt-2">
      <v-col cols="10">      
       <v-text-field v-model="search" solo rounded dense color="#F2BA55" label="Search for customers" prepend-inner-icon="mdi-magnify" elevation="0"></v-text-field>
      </v-col>
      <v-col cols="2">
        <v-btn @click=tocreateCustomer() fab dark color="#4B9C6D" elevation="0" small>
          <v-icon color="#ffffff" dark> mdi-account-plus</v-icon>
        </v-btn>
      </v-col>
    </v-row>

    <span class="searchResult">customers available</span>


      <div class="mt-16" v-if="customersAvailable" >
      <v-col cols="12">
          <v-card class="d-flex justify-center mr-2" color="#373535" dark elevation="0">
              <v-list color="#373535">
              <span class="d-flex justify-center">
              <v-icon class="ml-2 mt-8" size="90"> $notAvailableIcon </v-icon>
              </span>
              <span class="d-flex justify-center mt-5 cardTitle">Get started by creating customers.</span>
              </v-list>
          </v-card>
      </v-col>
      </div>
      <CircularProgress v-if="loader"/>
      <div class="row">
      <div class="col-md-4" :key="customerItem._id" v-for="customerItem in filteredCustomer">
      <ListCustomerCard :customerItem="customerItem" :toRemoveCustomer="toRemoveCustomer" />
      </div>
      </div>
    </div>
</template>
<script>
import router from '@/router'
import customerServices from '@/services/customerServices'
import ListCustomerCard from './ListCustomerCard.vue'
import CircularProgress from '../../loaders/CircularProgress.vue'
export default {
    components: {
        ListCustomerCard,
        CircularProgress
    },
    methods: {
        tocreateCustomer () {
            router.push('createCustomer')
        },
        async toViewCustomers(){
            this.loader = true
            const viewMyCustomers = (await customerServices.viewMyCustomers()).data;
            this.customerList = viewMyCustomers.viewAllCustomers
            if (this.customerList.length !=0) {
                this.customersAvailable = false
            } else {
                this.customersAvailable = true
            }
            this.loader = false
        },
        async toRemoveCustomer( id ){
            console.log(id);
            this.$swal.fire({
                title: 'Are you sure?',
                text: "You won't be able to revert this!",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Yes, delete it!'
                }).then((result) => {
                if (result.isConfirmed) {

                    customerServices.removeCustomer(id).then(res => {
                        if (res.data.status == "success") {
                            this.toViewCustomers();
                            this.loader = false;
                            this.$swal.fire(
                            'Deleted!',
                            res.data.message,
                            'success'
                            )
                        } else {
                            this.loader = false;
                            //this.errorMessage = res.data.message;
                        }
                    });
                }
            });
        }
    },
    data () {
        return {
            search: '',
            loader: false,
            customerList: [],
            customersAvailable: false
        }
    },
    async mounted () {
        this.toViewCustomers();
    },
    computed: {
        filteredCustomer: function () {
            var self = this;
            return this.customerList.filter(function (customer) {
                return customer.customerBasicInfo.customerName.toLowerCase().indexOf(self.search.toLowerCase()) >= 0
                || customer.customerBasicInfo.customerMobile.toLowerCase().indexOf(self.search.toLowerCase()) >= 0
                || customer.customerCommunityInfo.customerHouseNo.toLowerCase().indexOf(self.search.toLowerCase()) >= 0
                || customer.customerCommunityInfo.customerCommunityId.communityName.toLowerCase().indexOf(self.search.toLowerCase()) >= 0;
            });
        }
    }
}
</script>
<style scoped>
.cardTitle{
font-family: Lato;
font-style: normal;
font-weight: 900;
font-size: 12px;
color: #C7C6C6;
}
.searchResult {
font-family: Lato;
font-style: normal;
font-weight: 500;
font-size: 12px;
line-height: 22px;
color: rgba(255, 255, 255, 0.5);
}
</style>