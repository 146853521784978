<template>   
    <div>   
   <ListCustomerCards />
    </div>
</template>
<script>
import ListCustomerCards from './listCustomers/ListCustomerCards.vue'
  export default {
    data: () => ({}),
    components:{
        ListCustomerCards
    }
  }
</script>
<style scoped>
</style>